@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
iframe#webpack-dev-server-client-overlay {
	display: none !important;
}
body {
	font-family: "DM Sans", sans-serif;
}

option {
	color: black;
}

.react-tagsinput-input {
	width: 100% !important;
}
